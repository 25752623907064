import { useHistory ,useLocation } from 'react-router-dom';
import {HelmetHead} from '../utils/HelmetHead'
import FactorsOfXCalculator from './01-factors-of-X-Calculator'
import FactorsOfXLinks from './01-factors-of-X-Links'
import React from 'react'
import { useEffect } from 'react';
import {extractNumFromStr} from '../utils/functions'
import {FactorsArray,factorTree2,primeFactorsTable,negativeFactors,positiveFactors,howToCalculateFactors} from '../utils/factorCalculators'
import {questionType2,questionType1,questionType3} from '../utils/factorCalculators'





const FactorsOfX = ()=>{


  useEffect(()=> {
    // console.log([window.location.href])
    window.scrollTo(0, 0)
  })

  
  const location=useLocation();
  var factorNum=0;
  var number=0;

  
  number = parseInt(extractNumFromStr(location.pathname)[0])
  
  let primeFactorsTableStr=primeFactorsTable(number); 
  

  var num=number;
  var temp = number;




    
  var res=FactorsArray(number)

  const divisionParagraph = res.map(thing => <p key={thing}>{num} : {thing} = {num/thing}</p>)

  const facorPositivePairs = res.map(thing => <p key={thing}>{thing} x {num/thing} = {num}</p>)
  const facorNegativePairs = res.map(thing => <p key={thing}>({-(thing)}) x ({-(num/thing)}) = {num}</p>)

  const divisionNumbers = res.map( factor => <span className="divNumberStyle" key={factor} > {factor} 
                        <span className="divNumberStyleTip">({factor}x{number/factor})</span></span> ) 
  
  var title= `Factors of ${number}? `
  const metaTitle= `Is ${factorNum} a factor of ${number}? Free Factor finder calculator!`
  const decription = `Here we will show you if ${factorNum} is a factor of ${number} and what are ${number} factors`
  const articleStructuredData={}
  
  return (
    
    <div>
      <HelmetHead schema={articleStructuredData} title={title} description={decription} canonical={location.pathname}/>

      <h1>Factors of <span class="numberInTitle">{number}</span></h1>
      
      
      
      {/* {primeFactorTreeStr} */}
      
      
      The factors of {number} are the numbers that divide {number} without leaving a remainder. <br/>
      Factor pairs of {number} are pairs of numbers that when multiplied together will result in {number}.<br/>
      Factor pairs can also be negative.
      <ul>
        <li>If the number is positive, both factors in the pair has to be positive or negative.</li>
        <li>If the number is negative, one of the factors has to be negative and the other has to be positive.</li>
      </ul>
      <h3>In this lesson will learn and find</h3>
      <ul>
        <li>The factors of {number}</li>
        <li>Find factor pairs of {number}</li>
        <li>Find the prime factors of {number}</li>
        <li>Solve examples related to the factors of {number}</li>
        <li>Get the factor tree of {number}</li>
      </ul>



      <h2 className="h2Des">What are the factors of <span class="numberInTitle">{number}</span>?</h2>
      This question can be put in two ways:
      <ul>
        <li>What are the whole numbers that we'll get when dividing {temp} by another whole number?</li>
        <li>What are the 2 whole numbers that their multiplication will result in {temp}?</li>
      </ul>
      As mentions above the factors of {number} are the numbers that divide {number} evenly and the division remainder is 0.
      The simplest examples are dividing {number} by itself or by 1, this pair is a factor pair of each number:<br/>
      {number}:{number}=1<br/>
      {number}:1={number}<br/>
      <b>Note:</b> a number that is divisible without remainder only by itself and 1 is called <b>prime number</b>

      {howToCalculateFactors(number)}



      <h3 className="h3Des">The <b>positive</b> factors of <span class="numberInTitle">{number}</span></h3>
      Here are the factors of {number} - to find the factors of the number {number}, 
      we need to divide {number} by every number from 1 to to {number}, 
      only if the result is a whole number then we have a factor of {number}.
      <div className="dividingNumbers">{divisionNumbers}</div>

      <h3>Factors of as division pairs</h3>
      <div className="linksPart">{divisionParagraph}</div>

      <h3>Positive factors of <span class="numberInTitle">{number}</span></h3>
      <div className="linksPart">{facorPositivePairs}</div>

      {/* <h3>Negative factors of <span class="numberInTitle">{number}</span></h3>
      <div className="linksPart">{facorNegativePairs}</div> */}
      {negativeFactors(number)}

      {positiveFactors(number)}

      <h3>Factors of <span class="numberInTitle">{number}</span> - Prime factorization method</h3>
      The number {number} can be also written as the product of its prime factors.<br/>
      Here you can see how we found the prime factors of {number} step be step:
      {primeFactorsTableStr}

      
      {/* {primeFactorTreeStr} */}
      {factorTree2(number)}

      <FactorsOfXCalculator number={num} />


      <h3>Solved examples:</h3>
      {questionType1(number)}
      
      {questionType2(number)}

      {questionType3(number)}

      
      <h2  className="h2Des">Explore more factor and numbers examples:</h2>
      <FactorsOfXLinks numberStart={number} numberEnd={number+50}/>
    </div>
  )
}

export default FactorsOfX