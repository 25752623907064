import {Link} from 'react-router-dom'
import {useState} from 'react'

const WhatIsXDividedByAEquealsBCalculator = (props)=>{
  const [valueA,setValueA] = useState('')
  const [valueB,setValueB] = useState('')
  
  return (
    <div className="calc">
    
      <h2>What is X divided by {props.numberA} equals {props.numberB}</h2>  
      Try another question: <br/><br/>
      What is X divided by<br/><br/>
      <input onChange={inp => setValueA(inp.target.value)} className="calcInput" />
      equals
      <input onChange={inp => setValueB(inp.target.value)} className="calcInput" />
      <Link to={`what-is-x-divided-by-${valueA}-equals-${valueB}` }  className="calcBtn">Calculate</Link>
    </div>
  )
}

export default WhatIsXDividedByAEquealsBCalculator