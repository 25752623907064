import {Link} from 'react-router-dom'

const Header = ()=>{
  return (
    <div>

          <Link to="/division">Home</Link>

    </div>
  )
}

export default Header