import { useHistory ,useLocation } from 'react-router-dom';

import {HelmetHead} from '../utils/HelmetHead'
import DividedByCalculator from './01-DividedByXCalculator'
import DividedByLinks from './01-DividedByXLlnks'
import { useEffect } from 'react';
import React from 'react'





const WhatIsDividedByHP = ()=>{
  const location=useLocation()
  useEffect(()=> {
    // console.log([window.location.href])
    window.scrollTo(0, 0)
  })
  function stripUrl(path){
    return path.pathname
  }


  function calculate() { 
    var number1=100;
    var resultArr=[];
    
    var divArr=[];
    resultArr.push(1);

    var n;
    for (let i=2;i<number1;i++){
        if(number1%i==0){

            // console.log(i);
            divArr.push(i);
            resultArr.push(i);
        }
    }
    resultArr.push(number1); 
  
    return resultArr;
}
  
  var res=calculate()

  const divisionParagraph = res.map(thing => <p key={thing}>{100} / {thing} = {100/thing}</p>)

  const divisionNumbers = res.map( factor => 
            <span className="divNumberStyle" key={factor}> {factor} 
              <span className="divNumberStyleTip">({factor}x{100/factor})</span></span> ) 
  
  const title= `Is a number divided by anything?`
  const decription = `Here we will find what a whole number is divided by without remainder`
  const articleStructuredData={}
  
  return (
    
    <div>
      <HelmetHead schema={articleStructuredData} title={title} description={decription} canonical={location.pathname}/>
      <h1>Is <span class="numberInTitle">a number</span> divided by anything?</h1>
      <p>When asked if a number is divided by anything? We are acctually trying find the whole numbers that 
      divides any number without any remains.
      </p>
      <h2>There are a couple of ways to ask this question:</h2>
      <ul>
        <li>What are the whole numbers that we'll get when dividing a number by another whole number?</li>
        <li>What are the 2 whole numbers that their multiplication will result in a number?</li>
      </ul>
      <DividedByCalculator number={100}/>
      <h2  className="h2Des">Example - What 100 is divided by?</h2>
      <div className="dividingNumbers">{divisionNumbers}</div>
      <div className="linksPart">{divisionParagraph}</div>
      <DividedByCalculator number={100}/>
      <h2  className="h2Des">Explore more examples:</h2>
      <DividedByLinks numberOfLinks={1000} startingNum={1}/>
    </div>
  )
}

export default WhatIsDividedByHP