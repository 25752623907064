import {Link} from 'react-router-dom'

import React, { Component } from 'react';



const FactorsOfXLinks = (props)=>{

  var numberStart=props.numberStart;
  var numberEnd=props.numberEnd;


  let str='';
  
    for (let number=numberStart; number<numberEnd;number++){
      str=str+`<div><a key= ${number} className="LinkDes" href=factors-of-${number}>Factors of ${number}</a></div>`
    }
  




  return (
     
      <div className="linksPart" dangerouslySetInnerHTML={{__html: str}}/>

  )
}

export default FactorsOfXLinks