import { useLocation } from 'react-router-dom';
import {HelmetHead} from '../utils/HelmetHead'
import {extractNumFromStr} from './utils/functions'
import {convertInListText} from './utils/functions'
import DeciamlToBaseXCalculator from './01-decimal-to-x-base-calculator'
import DeciamlToBaseXLinks from './01-decimal-to-x-base-Links'

import React from 'react'
import { useEffect } from 'react';






const DeciamlToBaseX = ()=>{
  let number,base;

  const location=useLocation()
  useEffect(()=> {
    // console.log([window.location.href])
    window.scrollTo(0, 0)
  })

  const urlNumArr=extractNumFromStr(location.pathname);
  console.log(urlNumArr.length);
  if (urlNumArr.length==2){
    number = parseInt(extractNumFromStr(location.pathname)[0]);
    base = parseInt(extractNumFromStr(location.pathname)[1]);
  }else{
    number = 3000;
    base = parseInt(extractNumFromStr(location.pathname)[0]);
  }




  // console.log(number+"-"+base)

  
  
  
  
  const metaTitle= `Learn how to convert decimal number to base ${base}`
  const decription = `In this lesson you'll learn how to convert decimal number to base ${base}, with exmaples and easy instructions.`
  const articleStructuredData={}
  
  return (
    
    <div>
      <HelmetHead schema={articleStructuredData} title={metaTitle} description={decription} canonical={location.pathname}/>
      <h1>How to convert any number to base <span class="numberInTitle">{base}</span></h1>
      There is a relative simple algorithm to convert any decimal number to any other base.
      You can use the decimal to ant base calculator below, 
      just press in the decimal number you want to convert and the base you want to convert it to, 
      press the calculate button and a dedicated page page with a comprehensive step by step tutorial will be loaded
      <DeciamlToBaseXCalculator />
      In this page we will learn to convert a decimal number to base {base}, Lets start with converting <b>{number}</b> to base <b>{base}</b>:

      <h3>How to convert {number} to base {base} number in detailed steps:</h3>
      
      {convertInListText(number,base)}
      <DeciamlToBaseXCalculator />

      <DeciamlToBaseXLinks number={number} base={base} links={50}/>
    </div>
  )
}

export default DeciamlToBaseX
