import { useHistory ,useLocation } from 'react-router-dom';
import {Helmet} from 'react-helmet'
import DividedByXCalculator from './03-DividedByXCalculator'
import DividedByLinks from './03-DividedByXLlnks'
import { useEffect } from 'react';
import React from 'react'
import {HelmetHead} from '../utils/HelmetHead'
import {extractNumFromStr, alertMe} from '../utils/functions'
import {dividesBy6,dividesBy3,dividesBy9} from '../utils/divisibleFunc'






const IsXDividedBy = (props)=>{
  var number,factor;
  var str='';
  
  const location=useLocation()
  useEffect(()=> {
    // console.log([window.location.href])
    window.scrollTo(0, 0)
  })
  var numberString = extractNumFromStr(location.pathname)[0]
  
  number=parseInt(extractNumFromStr(location.pathname)[0])
  factor=parseInt(extractNumFromStr(location.pathname)[1])






  // let div3=dividesBy3(numberString);
  

  function createAnswerParagraph(factor){
    //division by ***2*** algorythem
    if(factor==2){
      let firstDigit=numberString[numberString.length-1];
      str=`Every number that is divisible by 2 ends with a 0,2,4,6 or 8. The number ${number} <strong>ends with ${firstDigit}.</strong><br/><br/>`;
      if(firstDigit=='0'||firstDigit=='2'||firstDigit=='4'||firstDigit=='7'||firstDigit=='8'){
        str=str+` Therefore <span class="spanRight">can be divided by ${factor}</span> without remaining (or results in a whole number)`;
      }else{
        str=str+` Therefore <span class="spanWrong">can't be divided by ${factor}</span> without remaining (or results in a whole number)`;
      }
    }
    //division by ***3*** algorythem
    else if(factor==3){

      str=dividesBy3(numberString);
    }
    //division by ***5*** algorythem
    else if(factor==5){
      let firstDigit=numberString[numberString.length-1];
      console.log(typeof(firstDigit))
      str=`Every number that is divisible by 5 ends with a 0 or 5. The number ${number} <strong>ends with ${firstDigit}.</strong><br/><br/>`;
      if(firstDigit=='5' || firstDigit=='0'){
        str=str+` Therefore <span class="spanRight">can be divided by ${factor}</span> without remaining (or results in a whole number)`;
      }else{
        str=str+` Therefore <span class="spanWrong">can't be divided by ${factor}</span> without remaining (or results in a whole number)`;
      }
    }else if(factor==6){
      str=dividesBy6(numberString);
    }else if(factor==9){
      str=dividesBy9(numberString);
    }
  }

  createAnswerParagraph(factor);
  
  
  
  const title= `Is 
  ${number} divided by ${factor}?`
  const decription = `Lets learn if ${number} is divisible by ${factor}? Read the answer and how to check if ${number} is divisible by ${factor} without using a calculator`
  
  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: 'Structured data for you',
    description: 'This is an article that demonstrates structured data.',
    image: './https://upload.wikimedia.org/wikipedia/commons/4/40/JSON-LD.svg',
    datePublished: new Date('2021-09-04T09:25:01.340Z').toISOString(),
    author: {
      '@type': 'Person',
      name: 'John Reilly',
      url: 'https://twitter.com/johnny_reilly',
    },
  };
  
  return (
    
    <div>

      
    <HelmetHead schema={articleStructuredData} title={title} description={decription} canonical={location.pathname}/>
      <h1>Is <span class="numberInTitle">{number}</span> divided by <span class="numberInTitle">{factor}</span>?</h1>
      <p>You asked if the number {number} can be divided by {factor} without remaining. <br /><br />
      In other words the question is wether {number}/{factor} results in a whole number. <br />
      Instead of using a calculator or one of our division tools, we would like to check the division by {factor} rules:</p>
      <div dangerouslySetInnerHTML={{ __html: `${str}`}} />

      
      <DividedByXCalculator number={number} factor={factor}/>
      <DividedByLinks number={number} factor={[2,4,5,6,9]}/>

      
    </div>
  )
}

export default IsXDividedBy