import { useHistory ,useLocation } from 'react-router-dom';
import {HelmetHead} from '../utils/HelmetHead'
import IsxFactorOfYCalculator from './02-IsxFactorOfYCalculator'
import IsxFactorOfLinks from './02-IsxFactorOfLinks'
import React from 'react'
import { useEffect } from 'react';
import {extractNumFromStr} from '../utils/functions'





const IsxFactorOfY = ()=>{


  useEffect(()=> {
    // console.log([window.location.href])
    window.scrollTo(0, 0)
  })


  const location=useLocation();
  var factorNum=0;
  var number=0;

  factorNum =  parseInt(extractNumFromStr(location.pathname)[0])
  number = parseInt(extractNumFromStr(location.pathname)[1])


  var num=number;
  var temp = number;
  function calculate() { 
    var number1=parseInt(num);
    var resultArr=[];
    
    var divArr=[];
    resultArr.push(1);
    var n;
    for (let i=2;i<number1;i++){
        if(number1%i==0){
            // console.log(i);
            divArr.push(i);
            resultArr.push(i);
        }
    }
    resultArr.push(number1);
  
    return resultArr;
  }

  function checkMethodOne(f,n){
    var str='';
    str=str+`<h3  class="h3Des">Method 1: Dividing <span class="numberInTitle">${n}</span> by <span class="numberInTitle">${f}</span> results in ?</h3>`;

    if(n%f==0){//Is factor
      str=str+`When we divide ${n} by ${f} and check if the quotient is a whole number. The result of ${n}/${f} is ${n/f}.
      ${n/f} is a whole number therefore <span class="spanRight">${f} is a factor of ${n}</span>`
    }else{//Isn't factor
      str=str+`When we divide ${n} by ${f} and check if the quotient is a whole number. The result of ${n}/${f} is ${parseFloat(n/f).toFixed(4)}. 
      ${parseFloat(n/f).toFixed(4)} is not a whole number therefore <span class="spanWrong">${f} is a not factor of ${n}</span>`
    }
    return str;
  }

  const methodOneHtml=checkMethodOne(factorNum,number)
    
  var res=calculate()

  const divisionParagraph = res.map(thing => <p key={thing}>{num} : {thing} = {num/thing}</p>)

  const divisionNumbers = res.map( factor => <span className="divNumberStyle" key={factor} > {factor} 
                        <span className="divNumberStyleTip">({factor}x{number/factor})</span></span> ) 
  
  var title= `Is ${factorNum} a factor of ${number}?`
  const metaTitle= `Is ${factorNum} a factor of ${number}?`
  const decription = `Here we will show you if ${factorNum} is a factor of ${number} and what are ${number} factors`
  const articleStructuredData={}
  
  return (
    
    <div>
      <HelmetHead schema={articleStructuredData} title={title} description={decription} canonical={location.pathname}/>

      <h1>Is <span class="numberInTitle">{factorNum}</span> a factor of <span class="numberInTitle">{number}</span>?</h1>
      
      
      
      <p>When asked if {factorNum} is a factor of {number}? We are asking if {number} is divided by {factorNum} without remainder? 
      or if the result of {number} divided by {factorNum} is a whole number?

      <h2 className="h2Des">There are a couple of ways to ask this question:</h2>
      <ul>
        <li>What are the whole numbers that we'll get when dividing {temp} by another whole number?</li>
        <li>What are the 2 whole numbers that their multiplication will result in {temp}?</li>
      </ul>

      <h2 className="h2Des">Lets check if <span class="numberInTitle">{factorNum}</span> is a factor of <span class="numberInTitle">{number}</span>:</h2>
      <p dangerouslySetInnerHTML={{ __html: `${methodOneHtml}`}} /></p>
      



      <h3 className="h3Des">Method 2: Checking the factors of <span class="numberInTitle">{number}</span></h3>
      Here are the factors of {number} - to find the factors of the number {number}, 
      we need to divide {number} by every number from 1 to to {number}, 
      only if the result is a whole number then we have a factor of {number}.
      <div className="dividingNumbers">{divisionNumbers}</div>
      <div className="linksPart">{divisionParagraph}</div>
      <IsxFactorOfYCalculator number={num}/>
      <h2  className="h2Des">Explore more factor and numbers examples:</h2>
      <IsxFactorOfLinks factorStart={factorNum} factorEnd={factorNum+10} numberStart={number} numberEnd={number+10}/>
    </div>
  )
}

export default IsxFactorOfY