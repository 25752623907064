import { useHistory ,useLocation } from 'react-router-dom';
import {Helmet} from 'react-helmet'
import DividedByXCalculator from './01-WhatIsXByYAsADecimalCalculator'
import DividedByLinks from './01-WhatIsXByYAsADecimalLlnks'
import { useEffect } from 'react';
import React from 'react'
import {HelmetHead} from '../utils/HelmetHead'
import {extractNumFromStr, alertMe} from '../utils/functions'
import Latext from 'react-latex';
import 'katex/dist/katex.min.css'
import boardImg from '../../images/boardBG.jpg'






const WhatIsXByYAsADecimal = (props)=>{
  var den,num;
  var str='';
  
  const location=useLocation()
  useEffect(()=> {
    // console.log([window.location.href])
    window.scrollTo(0, 0)
  })
  var numberString = extractNumFromStr(location.pathname)[0]
  
  num=parseInt(extractNumFromStr(location.pathname)[0])
  den=parseInt(extractNumFromStr(location.pathname)[1])
  let result = (num/den).toFixed(4);
  console.log(result)
  
  
  const title= `What is ${num} / ${den} as a decimal?`
  const decription = `Lets learn if ${num} is divisible by ${den}? Read the answer and how to check if ${num} is divisible by ${den} without using a calculator`

  function createParagraph(n,d){
    str='sdfsd';
    return str;
  }
  str=createParagraph(num,den);

  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: 'Structured data for you',
    description: 'This is an article that demonstrates structured data.',
    image: './https://upload.wikimedia.org/wikipedia/commons/4/40/JSON-LD.svg',
    datePublished: new Date('2021-09-04T09:25:01.340Z').toISOString(),
    author: {
      '@type': 'Person',
      name: 'John Reilly',
      url: 'https://twitter.com/johnny_reilly',
    },
  };
  
  
  return (
    
    <div>


    <HelmetHead schema={articleStructuredData} title={title} description={decription} canonical={location.pathname}/>
      <h1>What is <span class="numberInTitle">{num}</span>/<span class="numberInTitle">{den}</span> as a decimal?</h1>
      <div style={{ backgroundImage: `url(${boardImg})`,
                    backgroundRepeat: 'no-repeat',
                    width:'500px',
                    height:'347px',
                    margin:'20px auto',color:'white',fontSize:"2em", paddingTop:"1.5em",textAlign:"center"  }}>
      <Latext>{`$$\\frac{${num}}{${den}} $$ `}</Latext>  = {result} 
      <br/>OR<br/>
      <Latext>{` $$ ${num}/${den} $$ `}</Latext> = {result}
    </div>

      {num}/{den} can be also be represented as the fraction<Latext>{`$$\\frac{${num}}{${den}} $$`}</Latext><br/> 
      <Latext>{`$$\\frac{${num}}{${den}} $$ `}</Latext> or <Latext>{` $$ ${num}/${den} $$ `}</Latext> as a decimal is {result}
      
    </div>
  )
}

export default WhatIsXByYAsADecimal