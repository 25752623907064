import { useLocation } from 'react-router-dom';
import {HelmetHead} from '../utils/HelmetHead'
import {extractNumFromStr} from './utils/functions'
import {convertInListText} from './utils/functions'
import DeciamlToBaseXCalculator from './01-decimal-to-x-base-calculator'
import DeciamlToBaseXLinks from './01-decimal-to-x-base-Links'

import React from 'react'
import { useEffect } from 'react';






const DeciamlToBaseX = ()=>{
  let number,base;
  const location=useLocation()
  useEffect(()=> {
    // console.log([window.location.href])
    window.scrollTo(0, 0)
  })
  const arrLength = extractNumFromStr(location.pathname).length;
  console.log("Length:"+arrLength)
  if (arrLength==3){
    number = parseInt(extractNumFromStr(location.pathname)[1]);
    base = parseInt(extractNumFromStr(location.pathname)[2]);
  }


  console.log(number+"-"+base)

  
  
  
  
  const metaTitle= `Learn how to convert decimal ${number} to base ${base}`
  const decription = `In this lesson you'll learn how to convert decimal ${number} to base ${base}, with exmaples and easy instructions.`
  const articleStructuredData={}
  
  return (
    
    <div>
      <HelmetHead schema={articleStructuredData} title={metaTitle} description={decription} canonical={location.pathname}/>
      <h1>Convert the number <span class="numberInTitle">{number}</span> to base <span class="numberInTitle">{base}</span></h1>

      <h3>How to convert {number} to base {base} number in detailed steps:</h3>
      
      {convertInListText(number,base)}
      <DeciamlToBaseXCalculator />

      <DeciamlToBaseXLinks number={number} base={base} links={50}/>
    </div>
  )
}

export default DeciamlToBaseX