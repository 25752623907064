import {Link} from 'react-router-dom'
import {useState} from 'react'

const IsxFactorOfYCalculator = (props)=>{
  const [number,setValue] = useState('')
  const [factor,setFactor] = useState('')
  return (
    <div className="calc">
      <b>Try another question</b>
      Is<input onChange={inp => setValue(inp.target.value)} className="calcInput" />
      a factor of <input onChange={inp => setFactor(inp.target.value)} className="calcInput" />
      <Link to={`is-${number}-a-factor-of-${factor}` }  className="calcBtn">Calculate</Link>
    </div>
  )
}

export default IsxFactorOfYCalculator