import { useHistory ,useLocation } from 'react-router-dom';

import {HelmetHead} from '../utils/HelmetHead'
import DividedByCalculator from './01-DividedByXCalculator'
import DividedByLinks from './01-DividedByXLlnks'
import { useEffect } from 'react';
import React from 'react'





const WhatIsDividedBy = ()=>{
  const location=useLocation()
  useEffect(()=> {
    // console.log([window.location.href])
    window.scrollTo(0, 0)
  })
  function stripUrl(path){
    return path.pathname
  }
  
  const myPath=stripUrl(location)
  // console.log(myPath)
  var temp=myPath.replace('/division/what-is-x-divided-by/what-is-','')
  temp=temp.replace('-divided-by','')
  var num = eval(temp)

  function calculate() { 
    var number1=parseInt(num);
    var resultArr=[];
    
    var divArr=[];
    resultArr.push(1);

    var n;
    for (let i=2;i<number1;i++){
        if(number1%i==0){

            // console.log(i);
            divArr.push(i);
            resultArr.push(i);
        }
    }
    resultArr.push(number1); 
  
    return resultArr;
}
  
  var res=calculate()

  const divisionParagraph = res.map(thing => <p key={thing}>{num} / {thing} = {num/thing}</p>)

  const divisionNumbers = res.map( factor => 
            <span className="divNumberStyle" key={factor}> {factor} 
              <span className="divNumberStyleTip">({factor}x{num/factor})</span></span> ) 
  
  const title= `Is ${temp} divided by anything?`
  const decription = `Here we will find what whole number ${temp} is divided by without remainder`
  const articleStructuredData={}
  
  return (
    
    <div>
      <HelmetHead schema={articleStructuredData} title={title} description={decription} canonical={location.pathname}/>
      <h1>Is <span class="numberInTitle">{temp}</span> divided by anything?</h1>
      <p>When asked if the number {temp} is divided by anything? We are acctually trying find the whole numbers that 
      divides {temp} without any remains.
      </p>
      <h2>There are a couple of ways to ask this question:</h2>
      <ul>
        <li>What are the whole numbers that we'll get when dividing {temp} by another whole number?</li>
        <li>What are the 2 whole numbers that their multiplication will result in {temp}?</li>
      </ul>

      <h2  className="h2Des">The numbers that <span class="numberInTitle">{temp}</span> is divided by without (remainder)</h2>
      <div className="dividingNumbers">{divisionNumbers}</div>
      <div className="linksPart">{divisionParagraph}</div>
      <DividedByCalculator number={num}/>
      <h2  className="h2Des">Explore more examples:</h2>
      <DividedByLinks numberOfLinks={100} startingNum={num}/>
    </div>
  )
}

export default WhatIsDividedBy