import {Link} from 'react-router-dom'
import {useState} from 'react'

const DividedByXCalculator = (props)=>{
  const [value,setValue] = useState('')
  
  return (
    <div className="calc">
    
      <h2>Divisible by {props.factor}</h2>  
      Try another number <br/><br/>
      <input onChange={inp => setValue(inp.target.value)} className="calcInput" />
      <Link to={`is-${value}-divided-by-${props.factor}` }  className="calcBtn">Calculate</Link>
    </div>
  )
}

export default DividedByXCalculator