import { useHistory ,useLocation } from 'react-router-dom';
import {HelmetHead} from '../utils/HelmetHead'
import FactorsOfXCalculator from './01-factors-of-X-Calculator'
import FactorsOfXLinks from './01-factors-of-X-Links'
import React from 'react'
import { useEffect } from 'react';
import {primeFactors} from '../utils/factorCalculators'





const FactorsOfXHP = ()=>{




  useEffect(()=> {
    // console.log([window.location.href])
    window.scrollTo(0, 0)
  })
  const randomNumber = Math.floor(Math.random() * 200);
  console.log('Prime factors of', randomNumber + ':', primeFactors(randomNumber))
  // let primeFactorsTableStr=primeFactorsTable(324);

  const location=useLocation();
  var factorNum=0;
  var number=34;


  var num=number;
  var temp = number;
  function calculate() { 
    var number1=parseInt(num);
    var resultArr=[];
    
    var divArr=[];
    resultArr.push(1);
    var n;
    for (let i=2;i<number1;i++){
        if(number1%i==0){
            // console.log(i);
            divArr.push(i);
            resultArr.push(i);
        }
    }
    resultArr.push(number1);
  
    return resultArr;
  }

  function checkMethodOne(f,n){
    var str='';
    str=str+`<h3  class="h3Des">Method 1: Dividing <span class="numberInTitle">${n}</span> by <span class="numberInTitle">${f}</span> results in ?</h3>`;

    if(n%f==0){//Is factor
      str=str+`When we divide ${n} by ${f} and check if the quotient is a whole number. The result of ${n}/${f} is ${n/f}.
      ${n/f} is a whole number therefore <span class="spanRight">${f} is a factor of ${n}</span>`
    }else{//Isn't factor
      str=str+`When we divide ${n} by ${f} and check if the quotient is a whole number. The result of ${n}/${f} is ${parseFloat(n/f).toFixed(4)}. 
      ${parseFloat(n/f).toFixed(4)} is not a whole number therefore <span class="spanWrong">${f} is a not factor of ${n}</span>`
    }
    return str;
  }

  const methodOneHtml=checkMethodOne(factorNum,number)
    
  var res=calculate()

  const divisionParagraph = res.map(thing => <p key={thing}>{num} : {thing} = {num/thing}</p>)

  const divisionNumbers = res.map( factor => <span className="divNumberStyle" key={factor} > {factor} 
                        <span className="divNumberStyleTip">({factor}x{number/factor})</span></span> ) 
  
  var title= `Factors of ${number}? `
  const metaTitle= `Is ${factorNum} a factor of ${number}? Free Factor finder calculator!`
  const decription = `Here we will show you if ${factorNum} is a factor of ${number} and what are ${number} factors`
  const articleStructuredData={}
  
  return (
    
    <div>
      <HelmetHead schema={articleStructuredData} title={title} description={decription} canonical={location.pathname}/>

      <h1>Factor calculator for any number</h1>
      
      {/* {primeFactorsTableStr} */}
      <FactorsOfXCalculator number={num}/>
      The factors of {number} are the numbers that divide {number} without leaving a remainder. <br/>
      Factor pairs of {number} are pairs of numbers that when multiplied together will result in {number}.<br/>
      Factor pairs can also be negative.
      <ul>
        <li>If the number is positive, both factors in the pair has to be positive or negative.</li>
        <li>If the number is negative, one of the factors has to be negative and the other has to be positive.</li>
      </ul>
      <h3>In this lesson will learn and find</h3>
      <ul>
        <li>The factors of {number}</li>
        <li>Find factor pairs of {number}</li>
        <li>Find the prime factors of {number}</li>
        <li>Solve examples related to the pactors of {number}</li>
      </ul>



      <h2 className="h2Des">What are the factors of <span class="numberInTitle">{number}</span>?</h2>
      As mentions above the factors of {number} are the numbers that divide {number} evenly and the division remainder is 0.
      The simpliest examples are deviding {number} by itslef or by 1, this pair is a factor pair of each number:<br/>
      {number}:{number}=1<br/>
      {number}:1={number}<br/>
      <b>Note:</b> a number that is divisible without remainder only by itslef and 1 is called <b>prime number</b>
      
      



      <h3 className="h3Des">The factors of <span class="numberInTitle">{number}</span></h3>
      Here are the factors of {number} - to find the factors of the number {number}, 
      we need to divide {number} by every number from 1 to to {number}, 
      only if the result is a whole number then we have a factor of {number}.
      <div className="dividingNumbers">{divisionNumbers}</div>
      <div className="linksPart">{divisionParagraph}</div>
      <FactorsOfXCalculator number={num}/>

      <h2 className="h2Des">There are a couple of ways to ask this question:</h2>
      <ul>
        <li>What are the whole numbers that we'll get when dividing {temp} by another whole number?</li>
        <li>What are the 2 whole numbers that their multiplication will result in {temp}?</li>
      </ul>

      
      <h2  className="h2Des">Explore more factor and numbers examples:</h2>
      <FactorsOfXLinks numberStart={number} numberEnd={number+50}/>
    </div>
  )
}

export default FactorsOfXHP