import {useLocation } from 'react-router-dom';
import WhatIsXDividedByAEquealsBCalculator from './04-WhatIsXDividedByAEquealsBCalculator'
import WhatIsXDividedByAEquealsBLinks from './04-WhatIsXDividedByAEquealsBLlnks'
import { useEffect } from 'react';
import React from 'react'
import {HelmetHead} from '../utils/HelmetHead'
import {extractNumFromStr, alertMe} from '../utils/functions'
import Latext from 'react-latex';
import 'katex/dist/katex.min.css'
import boardImg from '../../images/boardBG.jpg'






const WhatDividediByAEqualsBHP = (props)=>{
  var numberA,numberB;
  var str='';
  
  const location=useLocation()
  useEffect(()=> {
    // console.log([window.location.href])
    window.scrollTo(0, 0)
  })
  // var numberString = extractNumFromStr(location.pathname)[0]
  
  numberA=16;
  numberB=5;

  

  function createAnswerParagraph(a,b){
    console.log(a+'-'+b)
    
  }

  createAnswerParagraph(numberA,numberB);
  
  
  
  const title= `What is X divided by ${numberA} equals to ${numberB}?`
  const decription = `Lets find what is X divided by ${numberA} equals to ${numberB}? Read the answer and how to calculate What is X divided by ${numberA} equals to ${numberB}?`
  
  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: 'Structured data for you',
    description: 'This is an article that demonstrates structured data.',
    image: './https://upload.wikimedia.org/wikipedia/commons/4/40/JSON-LD.svg',
    datePublished: new Date('2021-09-04T09:25:01.340Z').toISOString(),
    author: {
      '@type': 'Person',
      name: 'John Reilly',
      url: 'https://twitter.com/johnny_reilly',
    },
  };
  
  return (
    
    <div>

      
    <HelmetHead schema={articleStructuredData} title={title} description={decription} canonical={location.pathname}/>
      
      <h1>HP What is <span class="numberInTitle">X</span> divided by <span class="numberInTitle">{numberA}</span> equals to <span class="numberInTitle">{numberB}</span>?</h1>
      <p>When we look at the question What is <strong>X</strong> divided by <strong>{numberA}</strong> equals to <strong>{numberB}</strong>?
      We look at X as an unknown number that needs to be found<br/><br/>
      <div style={{ backgroundImage: `url(${boardImg})`}} class="boardImg">
      <Latext class="test">{`If $$ \\frac{X}{${numberA}}   = ${numberB}$$`}</Latext>
      <br/>then<br/>
      <Latext class="test">{` $$ X   = ${numberB*numberA}   $$`}</Latext>
    </div>
      Lets write the question as an equation:<br/>
      <Latext class="test">{` $$ \\frac{X}{${numberA}}   = ${numberB}$$`}</Latext><br/><br/>
      <strong>Step 1:</strong><br/> Multuplting both sides of the equation by {numberA}<br/>
      <Latext class="test">{` $$ \\frac{X}{${numberA}}   = ${numberB}   \\color{blue}/\\cdot ${numberA}$$`}</Latext><br/><br/>
      We'll get the following equation:<br/>
      <Latext class="test">{` $$ \\frac{${numberA}\\cdot X}{${numberA}}   = ${numberB}\\cdot${numberA}   $$`}</Latext><br/><br/>
      
      <strong>Step 2:</strong><br/> Reducing the left side of the equation and solving the multiplication at the right side of the equation:
      <Latext class="test">{` $$ \\frac{\\sout{${numberA}}\\cdot X}{\\sout{${numberA}}}   = ${numberB*numberA}   $$`}</Latext><br/><br/>

      <strong>Step 3:</strong><br/> We have the asnwer to x=?, lets write is in a nicer way:<br/>
      <Latext class="test">{` $$ X   = ${numberB*numberA}   $$`}</Latext><br/><br/>
      </p>
      <p>
        <strong>Checking:</strong><br/>
        If we want to check our answer, we simply place the found X in the initial question and verify
        that <br/>
        <Latext class="test">{` $$ \\frac{${numberA*numberB}}{${numberA}}   = ${numberB} \\color{green}\\surd$$ `}</Latext><br/><br/>
        Thats all!!!
      </p>
      <WhatIsXDividedByAEquealsBCalculator numberA={numberA} numberB={numberB}/>
      <WhatIsXDividedByAEquealsBLinks numberA={numberA} numberB={numberB} links={5}/>




      


      
    </div>
  )
}

export default WhatDividediByAEqualsBHP
