import {Link} from 'react-router-dom'
import {useState} from 'react'

const FactorsOfXCalculator = (props)=>{
  const [number,setValue] = useState('')
  // const [factor,setFactor] = useState('')
  return (
    <div className="calc">
      <b>Find the factors of</b>
      <input onChange={inp => setValue(inp.target.value)} className="calcInput" />
      
      <Link to={`factors-of-${number}` }  className="calcBtn">Find Factors</Link>
    </div>
  )
}

export default FactorsOfXCalculator