import {Link} from 'react-router-dom'

import React, { Component } from 'react';



const DeciamlToBaseXLinks = (props)=>{

  var numberStart=props.number;
  var numberEnd=numberStart+props.links;
  var base=props.base;


  let str='';
  
    for (let number=numberStart; number<numberEnd;number++){
      str=str+`<div><a key= ${number} className="LinkDes" href=convert-${number}-to-base-${base}>Convert ${number} to base ${base}</a></div>`
    }
  




  return (
     
      <div className="linksPart" dangerouslySetInnerHTML={{__html: str}}/>

  )
}

export default DeciamlToBaseXLinks