import './App.css';
import Header from './Header'
import DivisionHP from './DivisionHp'
import WhatIsDividedBy from './Division/components/01-DividedByX'
import WhatIsDividedByHP from './Division/components/01-HP-DividedByX'

import IsXDividedBy from './Division/components/03-DividedByX'
import IsXDividedByHP from  './Division/components/03-HP-DividedByX'
import WhatDividediByAEqualsB from './Division/components/04-WhatIsXDividedByAEquealsB'
import WhatDividediByAEqualsBHP from './Division/components/04-HP-WhatIsXDividedByAEquealsB'

import WhatIsXByYAsADecimal from './Decimals/Components/01-WhatIsXByYAsADecimal'

import IsxFactorOfY from './Division/components/02-IsxFactorOfY'
import IsxFactorOfYHP from './Division/components/02-HP-IsxFactorOfY'

import FactorsOfXHP from './Factors/components/01-HP-factors-of-X'
import FactorsOfX from './Factors/components/01-factors-of-X'

import DeciamlToBaseXHP from'./BaseConverters/01-HP-decimal-to-x-base-X'
import DeciamlToBaseX from './BaseConverters/01-decimal-to-x-base-X'

import {BrowserRouter, Route, Routes} from 'react-router-dom'


function App() {
  return (
    <div className="main">
      <BrowserRouter >
        <div className="App">
          <Header/>
        </div>
        
        <Routes>
          <Route path='/' element={<DivisionHP/>}/>
          <Route path='/division' element={<DivisionHP/>}/>
          <Route path='/division/what-is-x-divided-by/' element={<WhatIsDividedByHP/> }/>
          <Route path='/division/what-is-x-divided-by/*' element={<WhatIsDividedBy/>}/>


          <Route path='/division/is-x-divisible-by/' element={<IsXDividedByHP/>}/>
          <Route path='/division/is-x-divisible-by/*' element={<IsXDividedBy/>}/>
          <Route path='division/what-dividediby-a-equals-b/' element={<WhatDividediByAEqualsBHP/>}/>
          <Route path='division/what-dividediby-a-equals-b/*' element={<WhatDividediByAEqualsB/>}/>
          <Route path='/decimal' element={<WhatIsXByYAsADecimal/>}/>
          <Route path='/decimal/WhatIsXByYAsADecimal/*' element={<WhatIsXByYAsADecimal/>}/>

          <Route path='/factors/is-x-factor-of-y/' element={<IsxFactorOfYHP/>}/>
          <Route path='/factors/is-x-factor-of-y/*' element={<IsxFactorOfY/>}/>          
          <Route path='/factors/factors-of-x/' element={<FactorsOfXHP/>}/>
          <Route path='/factors/factors-of-x/*' element={<FactorsOfX/>}/>


          <Route path='/base-converters/convert-x-to-base-2' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-3' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-4' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-5' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-6' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-7' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-8' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-9' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-10' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-11' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-12' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-13' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-14' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-15' element={<DeciamlToBaseXHP/>}/>
          <Route path='/base-converters/convert-x-to-base-16' element={<DeciamlToBaseXHP/>}/>


          <Route path='/base-converters/convert-x-to-base-2/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-3/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-4/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-5/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-6/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-7/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-8/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-9/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-10/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-11/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-12/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-13/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-14/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-15/*' element={<DeciamlToBaseX/>}/>
          <Route path='/base-converters/convert-x-to-base-16/*' element={<DeciamlToBaseX/>}/>

          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
