import {Link} from 'react-router-dom'
import {useState} from 'react'

const DividedByCalculator = (props)=>{
  const [value,setValue] = useState('')
  return (
    <div className="calc">
    <h2>Explore what any number is divided by:</h2>
      <p>Try any number</p>
      <input onChange={inp => setValue(inp.target.value)} className="calcInput" />
      <Link to={`what-is-${value}-divided-by` }  className="calcBtn">Find results</Link>
    </div>
  )
}

export default DividedByCalculator