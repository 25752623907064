import {Person} from 'schema-dts'
import {JsonLd} from 'react-schemaorg'

export function dividesBy6(n){
  let str=`<div class="rules">Every number that is divisible by 2 and 3 is divisible by 6 as well<br/><br/>
  <strong>Example</strong>: The sum of the digits of the number 93 is 9+3=12, the number 12 is divisible by 3 (3x4=12), 
  but 93 isn't divisible by 2 as its last digit is 3, so the number 93 isn't divisible by 6.
  </div>`;
  let digitsSum=0;

  str=str+`Lets add the digits that make the number ${n}:<br/>`;
  str=str+`${n[0]}`
  digitsSum=digitsSum+parseInt(n[0]);
  for(let i=1;i<n.length-1;i++){
    str=str+`+${n[i]}`;
    digitsSum=digitsSum+parseInt(n[i]);
  }
  digitsSum=digitsSum+parseInt(n[n.length-1]);
  str=str+`+${n[n.length-1]}=${digitsSum}<br/><br/>`
  if(digitsSum%3 == 0){
    str=str+`The number ${digitsSum} is divisible by 3 <span class="smallF">(${digitsSum/3}x${3}=${digitsSum})</span>, 
    therefore <strong>the number ${n} is divisible by ${3}</strong>
    now lets also check if its divisible by 2.<br/><br/>
    A number that is divisible by 2 needs to end with an even digit (0,2,4,6,8), in our case, the number ${n} ends with ${n[n.length-1]}<br/>`
    if(parseInt(n[n.length-1])%2==0){
      str=str+`As the number ${n[n.length-1]} is even, the number ${n} is divisible by 2 as well.<br/>
      Because that the number ${n} is devisible by 2 and 3 then <span class="spanRight">${n} is divisible by 6 as well</span><br/>`;
    }else{
      str=str+`As the number ${n[n.length-1]} is odd, the number ${n} isn't divisible by 2.<br/>
      Because the number ${n} is devisible by 3 but not divisible by 2 then <span class="spanWrong">${n} isn't divisible by 6 as well</span><br/>`;        
    }
  }else{
    str=str+`The number ${digitsSum} isn't divisible by 3, therefore <span class="spanWrong">the number ${n} isn't divisible by ${3}</span><br/><br/>
    PS:As the number ${n} isn't divisible by 3, there is no need to check if its divisible by 2.<br/><br/>
    `
  }
  return str
}

export function dividesBy3(n){
  
  let firstDigit=n[n.length-1];
  let str=`<div class="rules">Every number is divisible by 3 if the sum of its digits is divisible by 3.<br/><br/>
  <strong>Example:</strong> the sum of digits of number 89 is 8+9=17. <br/>17 isn't divisible by 3 therefore 89 isn't divisible by 3!
  </div><br/><br/>`;
  str=str+`Lets add the digits that make the number ${n}:<br/>`;
  let temp=0;
  str=str+`${n[0]}`
  temp=temp+parseInt(n[0]);
  for(let i=1;i<n.length-1;i++){
    str=str+`+${n[i]}`;
    temp=temp+parseInt(n[i]);
  }
  temp=temp+parseInt(n[n.length-1]);
  str=str+`+${n[n.length-1]}=${temp}<br/>`
  if(temp%3 == 0){
    str=str+`The number ${temp} is divisible by 3, therefore <span class="spanRight">the number ${n} is divisible by ${3}</span>`
  }else{
    str=str+`The number ${temp} isn't divisible by 3, therefore <span class="spanWrong">the number ${n} isn't divisible by ${3}</span>`
  }
  return str
}

export function dividesBy9(n){
  
  let firstDigit=n[n.length-1];
  let str=`<div class="rules">Every number is divisible by 9 if the sum of its digits is divisible by 9.<br/><br/>
  <strong>Example:</strong> the sum of digits of number 89 is 8+9=17. <br/>17 isn't divisible by 9 therefore 89 isn't divisible by 3!
  </div><br/><br/>`;
  str=str+`Lets add the digits that make the number ${n}:<br/>`;
  let temp=0;
  str=str+`${n[0]}`
  temp=temp+parseInt(n[0]);
  for(let i=1;i<n.length-1;i++){
    str=str+`+${n[i]}`;
    temp=temp+parseInt(n[i]);
  }
  temp=temp+parseInt(n[n.length-1]);
  str=str+`+${n[n.length-1]}=${temp}<br/>`
  if(temp%9 == 0){
    str=str+`The number ${temp} is divisible by 9, therefore <span class="spanRight">the number ${n} is divisible by 9</span>`
  }else{
    str=str+`The number ${temp} isn't divisible by 9, therefore <span class="spanWrong">the number ${n} isn't divisible by 9</span>`
  }
  return str
}