import {NavLink} from 'react-router-dom'

import React, { Component } from 'react';

import {useState} from 'react'

const DividedByLinks = (props)=>{

  const numArr=[];
  
  function returnLinks(){
    var startNum=props.startingNum;
    var numOfLinks=props.numberOfLinks;
    for(let i=startNum;i<startNum+numOfLinks;i++){
      numArr.push(i)
    }  
  }
  
  returnLinks();
  const links = numArr.map(number => <NavLink key= {number} 
                                      className="LinkDes" 
                                      to={`what-is-${number}-divided-by`}>
                                      What is {number} divided by
                                      </NavLink>)
  


  return (
    <div className="linksPart">
      {links}
    </div>
  )
}

export default DividedByLinks