import arrowImg from'../images/arrow5.png'


export   function FactorsArray(n) { 
  // var number1=parseInt(num);
  var resultArr=[];
  
  var divArr=[];
  resultArr.push(1);
  var n;
  for (let i=2;i<n;i++){
      if(n%i==0){
          // console.log(i);
          divArr.push(i);
          resultArr.push(i);
      }
  }
  resultArr.push(n);

  return resultArr;
}


export function checkMethodOne(f,n){
  var str='';
  str=str+`<h3  class="h3Des">Method 1: Dividing <span class="numberInTitle">${n}</span> by <span class="numberInTitle">${f}</span> results in ?</h3>`;

  if(n%f==0){//Is factor
    str=str+`When we divide ${n} by ${f} and check if the quotient is a whole number. The result of ${n}/${f} is ${n/f}.
    ${n/f} is a whole number therefore <span class="spanRight">${f} is a factor of ${n}</span>`
  }else{//Isn't factor
    str=str+`When we divide ${n} by ${f} and check if the quotient is a whole number. The result of ${n}/${f} is ${parseFloat(n/f).toFixed(4)}. 
    ${parseFloat(n/f).toFixed(4)} is not a whole number therefore <span class="spanWrong">${f} is a not factor of ${n}</span>`
  }
  return str;
}


export function primeFactors(n) {
  const factors = [];
  let divisor = 2;

  while (n >= 2) {
    if (n % divisor == 0) {
      factors.push(divisor);
      n = n / divisor;
    } else {
      divisor++;
    }
  }
  return factors;
}
export function primeFactorsTable(n){
  let str='';
  let tempnumber=n;
  const factors = primeFactors(n);

  str=`<table class="simpleTable">
  <tbody>
  <tr>
  <td><b>Step</b></td>
  <td><b>Prime factor</b></td>
  <td><b>Division operation</b></td>
  
  </tr>`

  factors.forEach((factor,index)=>{
    str=str+`  <tr>
    <td>${index+1})Divide ${tempnumber} by ${factor}</td>
    <td>${factor}</td>
    <td>${tempnumber}:${factor} = ${tempnumber/factor}</td>
    </tr>`;
    tempnumber=tempnumber/factor;
  })
  
  str=str+`</tbody>
  </table>`;
  return <p dangerouslySetInnerHTML={{ __html: `${str}`}} />;
}

export function howToCalculateFactors(n){
  let str='';
  str=`<h3>How to calcualte the factors of <span class="numberInTitle">${n}</span></h3>
  Basically there is a method to find the factors of any number.<br/>
  1) Every number is divisible by 1 and itslef, so 1 and ${n} are the first to factors of ${n}<br/>
  2) Every even number is divisible by 2. `
  if(n%2==0){str=str+`The number ${n} is an even number so the pair 2 and ${n/2} are its second factor pair.<br/>`}
  else{
    str=str+`The number ${n} is an odd number therefore evey even number can't be a factor of ${n}, this helps in next steps.<br/>`
  }
  str=str+`3) Continue to divide ${n} by every number until you get to ${Math.floor(n/2)}. If the result is an whole number you find a new factor pair`
  str=str+`<br/><br/><b>More tips tp find factors of a number</b>
  <ul>
    <li>Every number that ends with 5 or 0 is divisible by 5, so 5 is one of its factors</li>
    <li>Every number that ends with 0 is divisible by 5,10 and 2</li>
    <li>Every number that the sum of its digits is divisible by 3 has 3 as its factor</li>
    <li>Any number that its last 2 digits is divisible by 4 is divisible by 4. For example 116 and 9987664 are divisible by 4</li>
    <li>Eny number that is divisible by 2 and 3 is divisible by 6</li>
  </ul>`
  return <p dangerouslySetInnerHTML={{ __html: `${str}`}} />;
}

export function factorTree2(n){
  let emptyCell='<div class="primeTreeCell2"></div>';
  
  let emptyCell2='<div class="primeTreeCell2"></div>';
  let rightArrowCell=`<div class="primeTreeArrowCell"><img style="width:30px;"src=${arrowImg}></div>`;
  let leftArrowCell=`<div class="primeTreeCell2"></div>`;

  let gridW=15;
  let offSet=0;
  let tempNumber=n;
  const factors = primeFactors(n);
  let str='';
  str=str+`<h3>Factor tree of <span class="numberInTitle">${n}</span></h3>
  Factor tree is a method to display the factors of a number, in this case we are looking for the prime factorization of a number.
  <br/>Every branch of the tree splits into 2 factors. The tree continues to split into 2 new branches 
  until the last number is prime as well
  `;
  str=str+`<div class="primeTree2">`;
  //building first row
  str=str+`${emptyCell}<div class="primeTreeCell first">${n}</div>`
  for(let i=offSet+2;i<gridW;i++){
    str=str+`${emptyCell2}`;
  }

  //building firstarrows line
  str=str+`${leftArrowCell}${rightArrowCell}`;
  for(let i=offSet+2;i<gridW;i++){
    str=str+`${emptyCell2}`;
  }
  offSet++;
  //buildig the tree
  factors.forEach((factor,index)=>{
    
    //factors line
    for(let i=0;i<index;i++){
      str=str+`${emptyCell}`;
    }
    str=str+`<div class="primeTreeCell red">${factor}</div>
    ${emptyCell}
    <div class="primeTreeCell blue">${tempNumber/factor}</div>`;
    for(let i=index+2;i<=gridW-2;i++){
      str=str+`${emptyCell2}`;
    }

    //arrows line
    if(tempNumber/factor !=1){
      //making sure not printing in the last line
      for(let i=0;i<index+1;i++){
        str=str+`${emptyCell}`;
      }
      str=str+`${leftArrowCell}${rightArrowCell}`;
      for(let i=index+2;i<=gridW-2;i++){
        str=str+`${emptyCell2}`;
      }
    }

    tempNumber=tempNumber/factor;
  })

  str=str+`</div>`;
  //writting the multiplication line
  str=str+`<h3>${n} as a muiltiplication of its prime factors:</h3>
  We can write the number ${n} as a multiplication of its priome factors:<br/>
  ${n}=`
  factors.forEach((factor,index)=>{
    if(index<factors.length-1){
      str=str+`${factor}X`
    }else{
      str=str+`${factor}`
    }

  })
  return <p dangerouslySetInnerHTML={{ __html: `${str}`}} />;
}

export function negativeFactorsStr(n){
  const factors = FactorsArray(n);
  let negativeString='';
  factors.forEach((factor,index)=>{
    if(index<factors.length){
      negativeString=negativeString+` (${-factor}) , `;
    }else{negativeString=negativeString+` (${-factor}) `;}
  })  
  return negativeString;
}

export function negativeFactors(n){
  let str='';
  str=`<h3>Negative factors of <span class="numberInTitle">${n}</span></h3>
  Theoratically every positive number can be the proruct of two negative numbers, therefore the number ${n} was also negative factors.<br/>
  In this case the negative factors of ${n}:<br/>${negativeFactorsStr(n)}`;

  return <p dangerouslySetInnerHTML={{ __html: `${str}`}} />;
}

export function positiveFactorsStr(n){
  const factors = FactorsArray(n);
  let factorsStr='';
  factors.forEach((factor,index)=>{
    if(index<factors.length-1){
      
      factorsStr=factorsStr+` ${factor} , `;
    }else{console.log("sdfsdf");factorsStr=factorsStr+` ${factor} `;}
  }) 
  return factorsStr;
}

export function positiveFactors(n){
  // const factors = FactorsArray(n);
 
  let str='';
  str=`<h3>Positive factors of <span class="numberInTitle">${n}</span></h3>
  The you divide ${n} by anyone of the following factors, you'll get a whole number. Here is the full list of positive factors for ${n}:<br/>${positiveFactorsStr(n)}`;

  return <p dangerouslySetInnerHTML={{ __html: `${str}`}} />;
}



export function questionType1(n){
  let str=``
  str=str+`<b>Question: What are the factors of ${n}?</b><br>`
  str=str+`Solution:${positiveFactorsStr(n)}<hr>`
  return <p dangerouslySetInnerHTML={{ __html: `${str}`}} />;
}


export function questionType2(n){
  let str=``
  str=str+`<b>Question: Fill the missing factors of ${n}?</b><br>`
  const factors = FactorsArray(n);
  
  factors.forEach((factor,index)=>{
    let rand=Math.random();
    if(rand>0.5){
      if(index<factors.length){
        str=str+`${factor} , `;
      }else{str=str+`${factor}`;}
    }else{
      if(index<factors.length){
        str=str+` ____ , `;
      }else{str=str+` ____ `;}
    }
  }) 

  str=str+`<br/>Solution:${positiveFactorsStr(n)} <hr>`


  return <p dangerouslySetInnerHTML={{ __html: `${str}`}} />;
}

export function questionType3(n){
  let str=``;
  str=str+`<b>Question: Fill the missing prime factors in the tree of ${n}?</b><br>`;
  
  
  let emptyCell='<div class="primeTreeCell2"></div>';
  
  let emptyCell2='<div class="primeTreeCell2"></div>';
  let rightArrowCell=`<div class="primeTreeArrowCell"><img style="width:30px;" src=${arrowImg}></div>`;
  let leftArrowCell=`<div class="primeTreeCell2"></div>`;

  let gridW=15;
  let offSet=0;
  let tempNumber=n;
  const factors = primeFactors(n);

  str=str+`<div class="primeTree2">`;
  //building first row
  str=str+`${emptyCell}<div class="primeTreeCell first">${n}</div>`
  for(let i=offSet+2;i<gridW;i++){
    str=str+`${emptyCell2}`;
  }

  //building firstarrows line
  str=str+`${leftArrowCell}${rightArrowCell}`;
  for(let i=offSet+2;i<gridW;i++){
    str=str+`${emptyCell2}`;
  }
  offSet++;
  //buildig the tree
  factors.forEach((factor,index)=>{
    
    //factors line
    for(let i=0;i<index;i++){
      str=str+`${emptyCell}`;
    }
    if(Math.random()>0.5){
      str=str+`<div class="primeTreeCell red">${factor}</div>`
    }else{
      str=str+`<div class="primeTreeCell red"></div>`
    }
    
    str=str+`${emptyCell}`
    if(Math.random()>0.5){
      str=str+`<div class="primeTreeCell blue">${tempNumber/factor}</div>`;
    }else{
      str=str+`<div class="primeTreeCell blue"></div>`;
    }
    
    for(let i=index+2;i<=gridW-2;i++){
      str=str+`${emptyCell2}`;
    }

    //arrows line
    if(tempNumber/factor !=1){
      //making sure not printing in the last line
      for(let i=0;i<index+1;i++){
        str=str+`${emptyCell}`;
      }
      str=str+`${leftArrowCell}${rightArrowCell}`;
      for(let i=index+2;i<=gridW-2;i++){
        str=str+`${emptyCell2}`;
      }
    }

    tempNumber=tempNumber/factor;
  })

  str=str+`</div>`;

  



  return <p dangerouslySetInnerHTML={{ __html: `${str}`}} />;
}


