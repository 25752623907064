import {NavLink} from 'react-router-dom'

import React, { Component } from 'react';


const DividedByLinks = (props)=>{
  let linksStr='<p>sdfsf</p>';
  let factorArr=props.factor;
  let anchorNumber=props.number;
  const numArr=[];
  
  function returnLinks(startingNum, range){
    var start;
    var end;

    if(start = startingNum-range<1){
      start=2;
      end = startingNum+range-(startingNum-range);
    }else{
      start = startingNum-range;
      end = startingNum+range;
    }

    for(let i=start;i<end;i++){
      numArr.push(i)
    }  
  }
  
  returnLinks(props.number,30);
  const links = factorArr.map(factor => 
                  <NavLink key= {factor} 
                                      className="LinkDes" 
                                      to={`is-${anchorNumber-1}-divisible-by-${factor}`}>
                                      Is {anchorNumber-1} divisible by {factor}
                                      </NavLink>
                              )
                                      
  
 function createLinks(n,f){
  linksStr='<p>sdfsf</p>';

 }

  return (
    <div className="linksPart">
      {links}
    </div>
      
  )
}

export default DividedByLinks