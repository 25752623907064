import {Link} from 'react-router-dom'

import React, { Component } from 'react';



const IsxFactorOfLinks = (props)=>{

  var numberStart=props.numberStart;
  var numberEnd=props.numberEnd;

  var factorStart=props.factorStart;
  var factorEnd=props.factorEnd;  
  console.log(typeof(factorStart))
  console.log(typeof(factorEnd))


  let str='';
  for (let factor=factorStart; factor<factorEnd;factor++){
    for (let number=numberStart; number<numberEnd;number++){
      str=str+`<div><a key= ${number} className="LinkDes" href=is-${factor}-a-factor-of-${number}>Is ${factor} a factor of ${number}</a></div>`
    }
  }




  return (
     
      <div className="linksPart" dangerouslySetInnerHTML={{__html: str}}/>

  )
}

export default IsxFactorOfLinks