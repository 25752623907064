import {Helmet} from 'react-helmet'


export function HelmetHead(props){
  var schemaStr='';
  if(props.schema){
    schemaStr=`    <script type="application/ld+json">
    {JSON.stringify(props.schema)}
  </script>`
  }
  return (
    <Helmet >
        
        <title>{ props.title }</title>
        <meta name="description" content={props.decription} />
        <link rel="canonical" href={props.canonical} />
    </Helmet>
  )
}