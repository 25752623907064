export function extractNumFromStr (str) {
  // var tName = location.pathname;
  var numbersFromString=str.match(/\d+/g);
  return numbersFromString;
}

export function alertMe(){
  alert("test")
}

export function convertInListText(number,base){
  let str=`<h3>How to convert ${number} to base ${base} number in steps:</h3>
  We will hae to devide ${number} by ${base}, when evry time we divide the number, the remainder is the a digit in the new base ${base} number and the quotient is divided again by ${base} until the quotient is 0.
  `

  return <p dangerouslySetInnerHTML={{ __html: `${str}`}} />;
}