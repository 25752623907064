import { useHistory ,useLocation } from 'react-router-dom';
import {Helmet} from 'react-helmet'
import DividedByXCalculator from './03-DividedByXCalculator'
import DividedByLinks from './03-DividedByXLlnks'
import { useEffect } from 'react';
import React from 'react'
import {HelmetHead} from '../utils/HelmetHead'
import {extractNumFromStr, alertMe} from '../utils/functions'
import {dividesBy6,dividesBy3,dividesBy9} from '../utils/divisibleFunc'






const IsXDividedByHP = (props)=>{
  var number,factor;
  var str='';
  
  const location=useLocation()
  useEffect(()=> {
    // console.log([window.location.href])
    window.scrollTo(0, 0)
  })
  
  
  number=369;
  var numberString='369'
  factor=3;






  // let div3=dividesBy3(numberString);
  

  function createAnswerParagraph(factor){
    //division by ***2*** algorythem
    console.log('sdfsd')
      str=dividesBy3(numberString);

  }

  createAnswerParagraph(factor);
  
  
  
  const title= `Is ${number} divided by ${factor}?`
  const decription = `Lets learn if ${number} is divisible by ${factor}? Read the answer and how to check if ${number} is divisible by ${factor} without using a calculator`
  
  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: 'Structured data for you',
    description: 'This is an article that demonstrates structured data.',
    image: './https://upload.wikimedia.org/wikipedia/commons/4/40/JSON-LD.svg',
    datePublished: new Date('2021-09-04T09:25:01.340Z').toISOString(),
    author: {
      '@type': 'Person',
      name: 'John Reilly',
      url: 'https://twitter.com/johnny_reilly',
    },
  };
  
  return (
    
    <div>

      
    <HelmetHead schema={articleStructuredData} title={title} description={decription} canonical={location.pathname}/>
      <h1>Is <span class="numberInTitle">{number}</span> divided by <span class="numberInTitle">{factor}</span>?</h1>
      <p>You asked if the number {number} can be divided by {factor} without remaining. <br /><br />
      In other words the question is wether {number}/{factor} results in a whole number. <br />
      Instead of using a calculator or one of our division tools, we would like to check the division by {factor} rules:</p>
      <div dangerouslySetInnerHTML={{ __html: `${str}`}} />

      
      <DividedByXCalculator number={number} factor={2}/>
      <DividedByXCalculator number={number} factor={3}/>
      <DividedByXCalculator number={number} factor={5}/>
      <DividedByXCalculator number={number} factor={6}/>
      <DividedByXCalculator number={number} factor={9}/>
      <DividedByLinks number={number} factor={[2,4,5,6,9]}/>

      
    </div>
  )
}

export default IsXDividedByHP