import {Link} from 'react-router-dom'
const DivisionHP = ()=>{
  
  return (
    <div>
      <h1 className="centerHeader" >Welcome to Math Stuff Index</h1>
      <h2>Division</h2>
      <div className="linksPart">
        <Link className="LinkDes" to="/division/what-is-x-divided-by/">What is X divided by</Link>      
        <Link className="LinkDes" to="/division/is-x-divisible-by/is-34-divisible-by-2">Is X divisible by 2</Link>
        <Link className="LinkDes" to="/division/is-x-divisible-by/is-34-divisible-by-3">Is X divisible by 3</Link>
        <Link className="LinkDes" to="/division/is-x-divisible-by/is-34-divisible-by-5">Is X divisible by 5</Link>
        <Link className="LinkDes" to="/division/is-x-divisible-by/is-34-divisible-by-6">Is X divisible by 6</Link>
        <Link className="LinkDes" to="/division/is-x-divisible-by/is-34-divisible-by-9">Is X divisible by 9</Link>      
      </div>
      <h2>Simple equations</h2>
      <div className="linksPart">
        <Link className="LinkDes" to="/division/what-dividediby-a-equals-b/what-is-x-divided-by-2-equals-16">What is X if X divided by 2 equals 16</Link>      
      </div>
      <h2>Factors</h2>
      <div className="linksPart">
        <Link className="LinkDes" to="/factors/factors-of-x/">What are the factors of X</Link>
        <Link className="LinkDes" to="/factors/is-x-factor-of-y/">Is X a fctor of Y</Link>
      </div>

      <h2>Decimal to other base convertor</h2>
      <div className="linksPart">
        <Link className="LinkDes" to="/base-converters/">Convert decimal to other base</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-2">Decimal to Binary converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-3">Decimal to Base 3 converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-4">Decimal to Base 4 converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-5">Decimal to Base 5 converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-6">Decimal to Base 6 converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-7">Decimal to Base 7 converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-8">Decimal to Octal converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-9">Decimal to Base 9 converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-10">Decimal to Base 10 converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-11">Decimal to Base 11 converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-12">Decimal to Base 12 converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-13">Decimal to Base 13 converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-14">Decimal to Base 14 converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-15">Decimal to Base 15 converter</Link>
        <Link className="LinkDes" to="/base-converters/convert-x-to-base-16">Decimal to Hexadecimal converter</Link>
      </div>
    </div>
  )
}

export default DivisionHP