import {Link} from 'react-router-dom'

import React, { Component } from 'react';


const WhatIsXDividedByAEquealsBLinks = (props)=>{

  let numArr=[];
  let nA=props.numberA;
  let nB=props.numberB;
  let nLinks=props.links;

  for (let i=nA; i<nA+nLinks;i++){
    numArr.push(i)
  }







  const links1 = numArr.map(number => <Link key= {number} 
      className="LinkDes" 
      to={`what-is-x-divided-by-${number}-equals-${nB}`}>
      What is X divided by {number} equals to {nB}?
    </Link>
    
  )

  const links2 = numArr.map(number => 
    <Link key= {number} 
      className="LinkDes" 
      to={`what-is-x-divided-by-${number}-equals-${nB+1}`}>
      What is X divided by {number} equals to {nB+1}?
    </Link>
    
    
  )
  const links0 = numArr.map(number => 
    <Link key= {number} 
      className="LinkDes" 
      to={`what-is-x-divided-by-${number}-equals-${nB-1}`}>
      What is X divided by {number} equals to {nB-1}?
    </Link>
    
    
  )



  return (
    <div>
      <h2>Explore more similar questions:</h2>
      <div className="linksPart">
        {links0}
        {links1}
        {links2}
      </div>
    </div>
  )
}

export default WhatIsXDividedByAEquealsBLinks