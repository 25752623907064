export function extractNumFromStr (str) {
  // var tName = location.pathname;
  var numbersFromString=str.match(/\d+/g);
  return numbersFromString;
}

export function alertMe(){
  alert("test")
}
export function reverseString(str) {
  return str.split("").reverse().join("");
}

export function convertInListText(number,base){
  let str=`
  We will have to divide ${number} by ${base}, when every time we divide the number, the remainder is the a digit in the new base ${base} number and the quotient is divided again by ${base} until the quotient is 0. Lets Start:
  <ul>`;

  let temp=number;

  let convertedNumber='';

  let tableStr=`<h3>How to convert ${number} to base ${base} number in table view:</h3><table class="table table-bordered"><tbody>
  <tr>
    <td><b>Divided by ${base}</b></td>
    <td><b>Quotient</b></td>
    <td><b>Remainder</b></td>
    <td><b>Base ${base} Value</b></td>
  </tr>
  `

  
  
  while(temp!=0){
    if (Math.floor(temp%base)<10){
      str=str+`<li>dividing ${temp} by ${base} results in quotient=${Math.floor(temp/base)} and the remainder=<b>${temp%base}</b></li>`
      tableStr=tableStr+  `<tr>
      <td>${temp} : ${base}</td>
      <td>${Math.floor(temp/base)}</td>
      <td>${temp%base}</td>
      <td>${temp%base}</td>
    </tr>`
      convertedNumber=convertedNumber+`${temp%base}`;  
    }else if (Math.floor(temp%base)==10){
      str=str+`<li>dividing ${temp} by ${base} results in quotient=${Math.floor(temp/base)} and the remainder=<b>10=>A</b></li>`  
      tableStr=tableStr+  `<tr>
      <td>${temp} : ${base}</td>
      <td>${Math.floor(temp/base)}</td>
      <td>${temp%base}</td>
      <td>A</td>
    </tr>`
      convertedNumber=convertedNumber+`A`;
    }else if (Math.floor(temp%base)==11){
      str=str+`<li>dividing ${temp} by ${base} results in quotient=${Math.floor(temp/base)} and the remainder=<b>11=>B</b></li>` 
      tableStr=tableStr+  `<tr>
      <td>${temp} : ${base}</td>
      <td>${Math.floor(temp/base)}</td>
      <td>${temp%base}</td>
      <td>B</td>
    </tr>` 
      convertedNumber=convertedNumber+`B`;
    }else if (Math.floor(temp%base)==12){
      str=str+`<li>dividing ${temp} by ${base} results in quotient=${Math.floor(temp/base)} and the remainder=<b>11=>C</b></li>`  
      tableStr=tableStr+  `<tr>
      <td>${temp} : ${base}</td>
      <td>${Math.floor(temp/base)}</td>
      <td>${temp%base}</td>
      <td>C</td>
    </tr>`
      convertedNumber=convertedNumber+`C`;
    }else if (Math.floor(temp%base)==13){
      str=str+`<li>dividing ${temp} by ${base} results in quotient=${Math.floor(temp/base)} and the remainder=<b>11=>D</b></li>`  
      tableStr=tableStr+  `<tr>
      <td>${temp} : ${base}</td>
      <td>${Math.floor(temp/base)}</td>
      <td>${temp%base}</td>
      <td>D</td>
    </tr>`
      convertedNumber=convertedNumber+`D`;
    }else if (Math.floor(temp%base)==14){
      str=str+`<li>dividing ${temp} by ${base} results in quotient=${Math.floor(temp/base)} and the remainder=<b>11=>E</b></li>`  
      tableStr=tableStr+  `<tr>
      <td>${temp} : ${base}</td>
      <td>${Math.floor(temp/base)}</td>
      <td>${temp%base}</td>
      <td>E</td>
    </tr>`
      convertedNumber=convertedNumber+`E`;
    }else if (Math.floor(temp%base)==15){
      str=str+`<li>dividing ${temp} by ${base} results in quotient=${Math.floor(temp/base)} and the remainder=<b>11=>F</b></li>`  
      tableStr=tableStr+  `<tr>
      <td>${temp} : ${base}</td>
      <td>${Math.floor(temp/base)}</td>
      <td>${temp%base}</td>
      <td>F</td>
    </tr>`
      convertedNumber=convertedNumber+`F`;
    }
    temp=Math.floor(temp/base);
  }
  tableStr=tableStr+`</tbody></table>`
  let powerStr=`(${number})<sub>10</sub> = `;
  let powerResStr=`(${number})<sub>10</sub> = `;
  for(let i=convertedNumber.length-1;i>=0;i--){
    if(i!=0){
      powerStr=powerStr+`${convertedNumber[i]}x${base}<sup>${i}</sup> + `
      powerResStr=powerResStr+`${convertedNumber[i]}x${Math.pow(base,i)}</sup> + `
    }else{
      powerStr=powerStr+`${convertedNumber[i]}x${base}<sup>${i}</sup>`
      powerResStr=powerResStr+`${convertedNumber[i]}x${Math.pow(base,i)}</sup>`
    }
    
  } 
  convertedNumber=reverseString(convertedNumber)
  console.log(convertedNumber)
  str=str+`</ul>
  The number ${number} converted to base ${base} is:<br>
  <p style="text-align:center; font-size:1.5em; font-weight:900;">  (${number})<sub>10</sub> = (${convertedNumber})<sub>${base}</sub></p>
  In other words we can write the number ${number} as an addition of numbers multiplied by ${base}<sup>x</sup>
  <br/>
  ${powerStr}<br/>OR<br/>
  ${powerResStr}
  ${tableStr}
  `

  

  return <p dangerouslySetInnerHTML={{ __html: `${str}`}} />;
}