import {Link} from 'react-router-dom'
import {useState} from 'react'

const DeciamlToBaseXCalculator = (props)=>{
  const [number,setValue] = useState('')
  const [base,setFactor] = useState('')
  return (
    <>
    <br/>
    
    <div className="calc">
    
      <b>Convert the decimal number</b>
      <input onChange={inp => setValue(inp.target.value)} className="calcInput" />
      <b>to base</b>
      <input onChange={inp => setFactor(inp.target.value)} className="calcInput" />
      <Link to={`/base-converters/convert-x-to-base-${base}/convert-${number}-to-base-${base}` }  className="calcBtn">Convert Number</Link>
    </div>
    </>
  )
}

export default DeciamlToBaseXCalculator